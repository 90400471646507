@font-face {
  font-family: 'basis-grotesque-medium-pro';
  src: url('./font/basis-grotesque-medium-pro.eot');
  src: url('./font/basis-grotesque-medium-pro.eot?#iefix') format('embedded-opentype'),
       url('./font/basis-grotesque-medium-pro.woff2') format('woff2'),
       url('./font/basis-grotesque-medium-pro.woff') format('woff'),
       url('./font/basis-grotesque-medium-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  background-color:#E5E5E5;
  margin: 0;
  font-family:basis-grotesque-medium-pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
@media (max-width:768px) {
  body {
    background-color:#fff;
  }
}
code {
  font-family: basis-grotesque-medium-pro;
}


p, * {
color: rgba(24, 22, 24, 1);
font-family: basis-grotesque-medium-pro;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
}

p.small  {
font-family: basis-grotesque-medium-pro;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
}

h1, h2 {
font-family: basis-grotesque-medium-pro;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
}

@media (max-width: 1024px){
  h1, h2 {
    font-family: basis-grotesque-medium-pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    }

    p, * { font-size:12px; line-height:18px;}
    p.small { font-size:10px; line-height:16px;}
}

@media (max-width:320px){
  h1, h2 {
    font-family: basis-grotesque-medium-pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin:0px;
    }
    p, * { font-size:12px; line-height:14px;}
    p.small { font-size:10px; line-height:12px;}
}